import cx from 'classnames';
import _upperFirst from 'lodash/upperFirst';
import { useMemo, useState, Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  TableContainer,
  TextField,
  Paper,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ForumIcon from '@material-ui/icons/Forum';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LaunchIcon from '@material-ui/icons/Launch';
import CancelIcon from '@material-ui/icons/Cancel';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert } from '@material-ui/lab';
import Activity from '../components/Activity';
import { formatCurrency, formatNumber } from '../lib/formatters';
import getAftermarketURL from '../lib/getAftermarketURL';
import useCountdown from '../lib/useCountdown';
import useWaitCursor from '../lib/useWaitCursor';

const SALE_PAGE_QUERY = gql`
  query SalePage($bidLimit: Int, $id: String!) {
    sale(id: $id, idType: id) {
      bidCount
      bidderCount
      createdDate
      endDate
      id
      isPartnerSale
      isPremiumRenewal
      price
      renewPrice
      saleType
      startDate
      startPrice
      status
      viewCount
      visibility
      activity(type: admin) {
        ...Activity
      }
      bids(limit: $bidLimit) {
        amount
        bidderIP
        createdDate
        id
        index
        isLeadingBid
        isProxy
        maxAmount
        source
        status
        user {
          id
          isExternal
          username
        }
      }
      invoices {
        expiredDate
        isPaid
        isRunnerup
        user {
          id
          username
        }
      }
      product {
        id
        name
        nsfw
        registeredDate
      }
    }
  }
  ${Activity.fragments.activity}
`;

const bidLimit = 100;

export default function Sale() {
  const [drawerView, setDrawerView] = useState(null);
  const [cancelReason, setCancelReason] = useState(null);
  const [note, setNote] = useState('');
  const [visibility, setVisibility] = useState('');
  const [bidStatusInput, setBidStatusInput] = useState('');
  const params = useParams();

  const saleQuery = useQuery(SALE_PAGE_QUERY, {
    variables: { bidLimit, id: params.id },
  });

  useEffect(() => {
    if (saleQuery.data) setVisibility(saleQuery.data.sale.visibility);
  }, [saleQuery.data]);

  const [attachAdminNote, attachAdminNoteQuery] = useMutation(gql`
    mutation AttachAdminNote($note: String!, $sale: String!) {
      attachAdminNote(input: { note: $note, sale: $sale }) {
        sale {
          id
          activity(type: admin) {
            ...Activity
          }
        }
      }
    }
    ${Activity.fragments.activity}
  `);

  const [cancelSale, cancelSaleQuery] = useMutation(gql`
    mutation CancelSale($sale: String!, $reason: SALE_CANCEL_REASON) {
      updateSale(
        input: { sale: $sale, status: cancelled, cancelReason: $reason }
      ) {
        sale {
          id
          status
          activity(type: admin) {
            ...Activity
          }
        }
      }
    }
    ${Activity.fragments.activity}
  `);

  const [markSold, markSoldQuery] = useMutation(gql`
    mutation MarkSold($sale: String!) {
      updateSale(input: { sale: $sale, status: sold }) {
        sale {
          id
          status
          activity(type: admin) {
            ...Activity
          }
        }
      }
    }
    ${Activity.fragments.activity}
  `);

  const [setBidStatus, setBidStatusQuery] = useMutation(
    gql`
      mutation UpdateBidStatus($bid: String!, $status: BID_STATUS!) {
        updateBidStatus(input: { bid: $bid, status: $status }) {
          bid {
            id
            status
          }
        }
      }
    `,
    {
      refetchQueries: ['SalePage'],
    },
  );

  const [setNSFW, setNSFWQuery] = useMutation(gql`
    mutation SetSaleNSFW($nsfw: Boolean!, $sale: String!) {
      updateSale(input: { nsfw: $nsfw, sale: $sale }) {
        sale {
          id
          product {
            nsfw
          }
        }
      }
    }
  `);

  const [setSaleVisibility, setSaleVisibilityQuery] = useMutation(
    gql`
      mutation SetSaleVisibility($visibility: VISIBILITY!, $sale: String!) {
        updateSale(input: { sale: $sale, visibility: $visibility }) {
          sale {
            id
            visibility
          }
        }
      }
    `,
    { refetchQueries: ['SalePage'] },
  );

  const countdown = useCountdown(saleQuery.data?.sale?.endDate);

  const onMarkSold = async () => {
    try {
      await markSold({
        variables: {
          sale: saleQuery.data.sale.id,
        },
      });
      if (note) {
        await attachAdminNote({
          variables: { note, sale: saleQuery.data.sale.id },
        });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  const onCancelSale = async () => {
    try {
      await cancelSale({
        variables: {
          sale: saleQuery.data.sale.id,
          reason: cancelReason,
        },
      });
      if (note) {
        await attachAdminNote({
          variables: { note, sale: saleQuery.data.sale.id },
        });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert(error.message);
    }
  };

  const onSubmitNote = async event => {
    event.preventDefault();
    try {
      await attachAdminNote({
        variables: { note, sale: saleQuery.data.sale.id },
      });
      setNote('');
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert('Failed to save note.');
    }
  };

  const onSetSaleVisibility = async event => {
    event.preventDefault();
    try {
      await setSaleVisibility({
        variables: { note, sale: saleQuery.data.sale.id, visibility },
      });
      if (note) {
        await attachAdminNote({
          variables: { note, sale: saleQuery.data.sale.id },
        });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert('Failed to update sale visibility.');
    }
  };

  const onSetBidStatus = async event => {
    event.preventDefault();
    try {
      const bid = saleQuery.data.sale.bids.find(bid => bid.isLeadingBid);
      await setBidStatus({
        variables: { bid: bid.id, status: bidStatusInput },
      });
      if (note) {
        await attachAdminNote({
          variables: { note, sale: saleQuery.data.sale.id },
        });
        setNote('');
      }
      setDrawerView(null);
    } catch (error) {
      console.error(error);
      window.alert('Failed to update bid');
    }
  };

  const activity = useMemo(() => {
    if (!saleQuery.data) {
      return [];
    }
    return [
      {
        createdDate: saleQuery.data.sale.createdDate,
        title: 'Sale created',
      },
      {
        createdDate: saleQuery.data.sale.startDate,
        title: 'Sale started',
      },
      ...saleQuery.data.sale.activity,
    ]
      .reverse()
      .map(item => {
        const details = {};
        if (item.user) {
          details['User'] = (
            <Link component={RouterLink} to={`/users/${item.user.id}`}>
              {item.user.username}
            </Link>
          );
        }
        if (item.bid) {
          details['Bid index'] = item.bid.index;
        }
        if (item.comment) {
          details['Comment ID'] = item.comment.idDatabase;
        }
        if (
          ['SET_BID_STATUS', 'SET_COMMENT_STATUS', 'SET_SALE_STATUS'].includes(
            item.type,
          )
        ) {
          details['New status'] = item.description;
        }
        if (item.type === 'SET_SALE_VISIBILITY') {
          details['New visibility'] = item.description;
        }
        if (item.type === 'ADD_ISSUE') {
          details['Issue'] = (
            <Link component={RouterLink} to={`/issues#${item.issue.id}`}>
              #{item.issue.id}: {item.issue.type} ({item.issue.description})
            </Link>
          );
        }
        return {
          ...item,
          details,
        };
      });
  }, [saleQuery.data]);

  useWaitCursor(
    [
      attachAdminNoteQuery,
      cancelSaleQuery,
      markSoldQuery,
      saleQuery,
      setBidStatusQuery,
      setNSFWQuery,
      setSaleVisibilityQuery,
    ].some(({ loading }) => loading),
  );

  if (saleQuery.error) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        {saleQuery.error.message}
      </Alert>
    );
  }

  if (saleQuery.data && !saleQuery.data.sale) {
    return (
      <Alert className="m-4" severity="error" variant="filled">
        Sale not found
      </Alert>
    );
  }

  if (!saleQuery.data) {
    return null;
  }

  const isEnded = new Date(saleQuery.data.sale.endDate) < new Date();

  return (
    <>
      <Helmet>
        <title>{saleQuery.data.sale.product.name}</title>
      </Helmet>
      <div className="py-4">
        <div className="p-4 lg:px-8">
          <Typography className="break-words" variant="h4">
            <span className="mr-4">{saleQuery.data.sale.product.name}</span>
            <Chip
              className="mr-2"
              label={_upperFirst(saleQuery.data.sale.status)}
            />
            {saleQuery.data.sale.isPartnerSale ? (
              <Chip className="mr-2" label="Partner" />
            ) : null}
          </Typography>
          <Divider className="mt-4" />
          <div className="flex flex-wrap items-center lg:justify-start my-3">
            <Button
              className="p-3"
              classes={{
                label: 'flex-col normal-case',
                startIcon: 'mx-0 mb-2',
              }}
              onClick={() =>
                window.open(
                  getAftermarketURL(`/sale/${saleQuery.data.sale.id}`),
                )
              }
              startIcon={<LaunchIcon />}
              variant="text">
              View sale
            </Button>
            <Button
              className="p-3"
              classes={{
                label: 'flex-col normal-case',
                startIcon: 'mx-0 mb-2',
              }}
              onClick={() => setDrawerView('setVisibility')}
              startIcon={<VisibilityIcon />}
              variant="text">
              Set visibility
            </Button>
            <Button
              className="p-3"
              classes={{
                label: 'flex-col normal-case',
                startIcon: 'mx-0 mb-2',
              }}
              onClick={() => setDrawerView('addNote')}
              startIcon={<CreateIcon />}
              variant="text">
              Add note
            </Button>
            <Button
              className="p-3"
              classes={{
                label: 'flex-col normal-case',
                startIcon: 'mx-0 mb-2',
              }}
              onClick={() =>
                setNSFW({
                  variables: {
                    nsfw: !saleQuery.data.sale.product.nsfw,
                    sale: saleQuery.data.sale.id,
                  },
                })
              }
              startIcon={<WarningIcon />}
              variant="text">
              {saleQuery.data.sale.product.nsfw ? 'Unmark' : 'Mark'} NSFW
            </Button>
            {['active', 'future', 'ended'].includes(
              saleQuery.data.sale.status,
            ) && (
              <Button
                className="p-3"
                classes={{
                  label: 'flex-col normal-case',
                  startIcon: 'mx-0 mb-2',
                }}
                onClick={() => setDrawerView('cancelSale')}
                startIcon={<CancelIcon />}
                variant="text">
                Cancel sale
              </Button>
            )}
            {saleQuery.data.sale.status === 'ended' && (
              <Button
                className="p-3"
                classes={{
                  label: 'flex-col normal-case',
                  startIcon: 'mx-0 mb-2',
                }}
                onClick={() => setDrawerView('markSold')}
                startIcon={<CheckIcon />}
                variant="text">
                Mark sold
              </Button>
            )}
          </div>
          <div className="mt-6 mb-2">
            <Typography variant="overline">Details</Typography>
            <Divider />
          </div>
          <List disablePadding>
            <ListItem dense disableGutters>
              <ListItemAvatar>
                <Avatar>
                  <ShowChartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`Price: ${formatCurrency(saleQuery.data.sale.price)}`}
                secondary={`Starting price: ${formatCurrency(
                  saleQuery.data.sale.startPrice,
                )}, Renew fee: ${formatCurrency(
                  saleQuery.data.sale.renewPrice,
                )}${saleQuery.data.sale.isPremiumRenewal ? ' (premium)' : ''}`}
              />
            </ListItem>
            {saleQuery.data.sale.saleType === 'auction' && (
              <ListItem dense disableGutters>
                <ListItemAvatar>
                  <Avatar>
                    <ForumIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${saleQuery.data.sale.bidCount} bids`}
                  secondary={`${saleQuery.data.sale.bidderCount} bidders`}
                />
              </ListItem>
            )}
            <ListItem dense disableGutters>
              <ListItemAvatar>
                <Avatar>
                  <HourglassEmptyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  saleQuery.data.sale.status === 'cancelled'
                    ? 'Sale cancelled'
                    : `Time remaining: ${countdown || '…'}`
                }
                secondary={(() => {
                  const formattedDate = new Date(
                    saleQuery.data.sale.endDate,
                  ).toString();
                  return isEnded
                    ? `Ended on ${formattedDate}`
                    : `Scheduled to end on ${formattedDate}`;
                })()}
              />
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemAvatar>
                <Avatar>
                  <BarChartIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${formatNumber(saleQuery.data.sale.viewCount)} views`}
              />
            </ListItem>
          </List>
        </div>
        {saleQuery.data.sale.invoices?.length > 0 && (
          <div className="p-4 lg:px-8">
            <div className="mt-4">
              <Typography variant="overline">Invoices</Typography>
            </div>
            <TableContainer component={Paper} elevation={0} variant="outlined">
              <Table className="whitespace-nowrap" aria-label="Invoices">
                <TableHead>
                  <TableRow>
                    <TableCell>User</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Runnerup</TableCell>
                    <TableCell>Expired Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleQuery.data.sale.invoices.map((invoice, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          component={RouterLink}
                          to={`/users/${invoice.user.id}`}>
                          {invoice.user.username}
                        </Link>
                      </TableCell>
                      <TableCell>{invoice.isPaid ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{invoice.isRunnerup ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <span
                          className={
                            !invoice.isPaid &&
                            !invoice.isRunnerup &&
                            new Date(invoice.expiredDate) < new Date()
                              ? 'text-red-500'
                              : ''
                          }>
                          {new Date(invoice.expiredDate).toLocaleString()}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {saleQuery.data.sale.saleType === 'auction' && (
          <div className="p-4 lg:px-8">
            <div className="mt-4">
              <Typography variant="overline">Bids</Typography>
            </div>
            <TableContainer component={Paper} elevation={0} variant="outlined">
              <Table className="whitespace-nowrap" aria-label="Bids">
                <TableHead>
                  <TableRow>
                    <TableCell>Index</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Max amount</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Bidder</TableCell>
                    <TableCell>Bidder IP</TableCell>
                    <TableCell>Source</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {saleQuery.data.sale.bids.map((bid, index) => (
                    <TableRow
                      className={cx({
                        italic: bid.status !== 'processed',
                      })}
                      key={bid.id}>
                      <TableCell>
                        {bid.index || saleQuery.data.sale.bids.length - index}
                      </TableCell>
                      <TableCell>{formatCurrency(bid.amount)}</TableCell>
                      <TableCell>
                        {bid.maxAmount
                          ? bid.maxAmount !== bid.amount &&
                            formatCurrency(bid.maxAmount)
                          : '--'}
                      </TableCell>
                      <TableCell>
                        {new Date(bid.createdDate).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {bid.user.username ? (
                          <Link
                            component={RouterLink}
                            to={`/users/${bid.user.id}`}>
                            {bid.user.username}
                          </Link>
                        ) : (
                          '--'
                        )}
                      </TableCell>
                      <TableCell>
                        {bid.isProxy || !bid.bidderIP ? '--' : bid.bidderIP}
                      </TableCell>
                      <TableCell>{bid.source ? bid.source : '--'}</TableCell>
                      <TableCell>
                        <div className="inline-flex items-center">
                          <span
                            className={cx({
                              'text-red-500': bid.status === 'cancelled',
                            })}>
                            {bid.status}
                          </span>
                          {bid.isLeadingBid &&
                            saleQuery.data.sale.status === 'active' &&
                            !saleQuery.data.sale.isPartnerSale && (
                              <div className="ml-4">
                                <IconButton
                                  aria-label="edit bid"
                                  color="inherit"
                                  edge="start"
                                  onClick={() => setDrawerView('editBid')}
                                  size="small">
                                  <EditIcon className="w-4 h-4" />
                                </IconButton>
                              </div>
                            )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                  {saleQuery.data.sale.bids.length === 0 && (
                    <TableRow>
                      <TableCell className="text-center" colSpan={8}>
                        No bids have been placed.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {saleQuery.data.sale.bids.length >=
              saleQuery.variables.bidLimit && (
              <Button
                className="mt-4"
                color="primary"
                onClick={() =>
                  saleQuery.refetch({
                    bidLimit: saleQuery.variables.bidLimit + bidLimit,
                  })
                }
                type="button">
                Load More
              </Button>
            )}
          </div>
        )}
        <div className="p-4 lg:px-8">
          <div className="my-4">
            <Typography variant="overline">Activity</Typography>
            <Divider />
          </div>
          <Activity activity={activity} />
        </div>
      </div>
      <Drawer
        anchor="right"
        open={!!drawerView}
        onClose={() => setDrawerView(null)}>
        <div className="flex p-3">
          <IconButton onClick={() => setDrawerView('')}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        {drawerView === 'addNote' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={onSubmitNote}>
            <Typography gutterBottom variant="h5">
              Add a note
            </Typography>
            <TextField
              className="my-4"
              fullWidth
              label="Note"
              multiline
              onChange={event => setNote(event.target.value)}
              required
              value={note}
              variant="outlined"
            />
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Add note
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'cancelSale' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              if (
                window.confirm(
                  `Are you sure you want to cancel sale of ${saleQuery.data.sale.product.name}? This cannot be undone.`,
                )
              ) {
                onCancelSale();
              }
            }}>
            <Typography gutterBottom variant="h5">
              Cancel sale of {saleQuery.data.sale.product.name}
            </Typography>
            <div className="my-6">
              <FormControl className="w-full" variant="outlined">
                <InputLabel htmlFor="cancel-reason">Select a reason</InputLabel>
                <Select
                  inputProps={{
                    id: 'cancel-reason',
                  }}
                  native
                  value={cancelReason || ''}
                  label="Select a reason"
                  onChange={event => setCancelReason(event.target.value)}>
                  <option aria-label="None" value="" />
                  <option value="renewal">Domain renewed</option>
                  <option value="transfer">Domain transfered</option>
                </Select>
              </FormControl>
            </div>
            <div className="my-6">
              <TextField
                fullWidth
                label="Note"
                multiline
                onChange={event => setNote(event.target.value)}
                required
                value={note}
                variant="outlined"
              />
            </div>
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Cancel sale
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'markSold' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              if (
                window.confirm(
                  `Are you sure you want to mark as sold the sale of ${saleQuery.data.sale.product.name}? This cannot be undone.`,
                )
              ) {
                onMarkSold();
              }
            }}>
            <Typography gutterBottom variant="h5">
              Mark {saleQuery.data.sale.product.name} as sold
            </Typography>
            <div className="my-6">
              <TextField
                fullWidth
                label="Note"
                multiline
                onChange={event => setNote(event.target.value)}
                required
                value={note}
                variant="outlined"
              />
            </div>
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Mark sold
              </Button>
            </div>
          </form>
        )}
        {drawerView === 'setVisibility' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={onSetSaleVisibility}>
            <Typography gutterBottom variant="h5">
              Set visibility for sale {saleQuery.data.sale.product.name}
            </Typography>
            <div className="my-6">
              <FormControl className="w-full" variant="outlined">
                <InputLabel htmlFor="cancel-reason">Select one</InputLabel>
                <Select
                  native
                  value={visibility || ''}
                  label="Select one"
                  onChange={event => setVisibility(event.target.value)}
                  inputProps={{
                    id: 'cancel-reason',
                  }}>
                  <option value="public">Public</option>
                  <option value="unlisted">Unlisted</option>
                  <option value="private">Private</option>
                </Select>
              </FormControl>
            </div>
            <div className="my-6">
              <TextField
                fullWidth
                label="Note"
                multiline
                onChange={event => setNote(event.target.value)}
                required
                value={note}
                variant="outlined"
              />
            </div>
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Save
              </Button>
            </div>
            <Typography variant="h6">Definitions</Typography>
            <Typography variant="body2" component="dl">
              <dt className="font-bold mt-4">Public</dt>
              <dd className="mb-4">Sale appears in search results.</dd>
              <dt className="font-bold">Unlisted</dt>
              <dd className="mb-4">
                Sale does not appear in search results, but can be viewed by
                direct link.
              </dd>
              <dt className="font-bold">Private</dt>
              <dd>Sale is hidden and not visible on the front end.</dd>
            </Typography>
          </form>
        )}
        {drawerView === 'editBid' && (
          <form
            className="p-8 max-w-screen-sm w-screen"
            onSubmit={event => {
              event.preventDefault();
              if (
                window.confirm(
                  `Are you sure you want to set the current leading bid's status to "${bidStatusInput}"? This cannot be undone.`,
                )
              ) {
                onSetBidStatus(event);
              }
            }}>
            <Typography gutterBottom variant="h5">
              Edit Leading Bid
            </Typography>
            <div className="my-6">
              <FormControl className="w-full" variant="outlined">
                <InputLabel htmlFor="bid-status">Select new status</InputLabel>
                <Select
                  native
                  value={bidStatusInput || ''}
                  label="Select new status"
                  onChange={event => setBidStatusInput(event.target.value)}
                  inputProps={{
                    id: 'bid-status',
                  }}>
                  <option aria-label="None" value="" />
                  <option value="invalid">Invalid</option>
                  <option value="fraud">Fraud</option>
                </Select>
              </FormControl>
            </div>
            <div className="my-6">
              <TextField
                fullWidth
                label="Note"
                multiline
                onChange={event => setNote(event.target.value)}
                required
                value={note}
                variant="outlined"
              />
            </div>
            <div className="flex justify-end">
              <Button color="primary" type="submit" variant="contained">
                Save
              </Button>
            </div>
          </form>
        )}
      </Drawer>
    </>
  );
}
